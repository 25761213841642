"use client"

import { Link, usePlatformState } from "@store-platform/ui/common"
import { SvgStoreLogo, SvgStoreLogoIcon } from "@store-platform/ui/icons"
import { getNewPlatformLink } from "@store-platform/utils"

export const NavbarLogo = () => {
  const globalStatePlatform = usePlatformState()

  return (
    <Link
      href={
        globalStatePlatform
          ? getNewPlatformLink({}, globalStatePlatform, true)
          : "/"
      }
      variant="naked"
    >
      <div className="flex items-center">
        <SvgStoreLogo className="block sm:hidden lg:block h-7 w-auto" />
        <SvgStoreLogoIcon className="hidden sm:block lg:hidden h-7 w-auto" />
      </div>
    </Link>
  )
}
