"use client"
import {
  ListingPlatform,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_MACOS,
  PLATFORM_PWA,
  PLATFORM_WEB_APP,
  PLATFORMS,
} from "@store-platform/types"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useClientBrowseState,
  usePlatformState,
  useSetClientState,
} from "@store-platform/ui/common"
import {
  SvgAndroid,
  SvgDevices,
  SvgIos,
  SvgMacOs,
  SvgWebapp,
} from "@store-platform/ui/icons"
import { getNewPlatformLink, resolvePlatformTitle } from "@store-platform/utils"
import { usePathname, useRouter } from "next/navigation"
import { createElement, useEffect, useMemo } from "react"

export const PlatformSelector = () => {
  const browsingState = useClientBrowseState()
  const router = useRouter()
  const pathname = usePathname()
  const globalStatePlatform = usePlatformState()
  const setClientState = useSetClientState()
  const isFeaturedRoute = pathname.startsWith("/featured/") || pathname === "/"
  const isBrowsingUrl =
    ["/browse", "/search"].includes(pathname) ||
    pathname.startsWith("/browse/") ||
    pathname.startsWith("/search/") ||
    isFeaturedRoute

  useEffect(() => {
    if (
      isBrowsingUrl &&
      (browsingState.platform ?? "all") !== (globalStatePlatform ?? "all")
    ) {
      setClientState({ platform: browsingState.platform })
    }
  }, [
    isBrowsingUrl,
    setClientState,
    browsingState,
    globalStatePlatform,
    router,
    isFeaturedRoute,
  ])

  const value = useMemo(
    () => globalStatePlatform ?? browsingState.platform,
    [globalStatePlatform, browsingState.platform],
  )

  return (
    <Select
      value={value ?? "all"}
      onValueChange={(value) => {
        setClientState({ platform: value as ListingPlatform | undefined })
        if (isBrowsingUrl) {
          router.push(
            getNewPlatformLink(
              browsingState,
              value as ListingPlatform,
              isFeaturedRoute,
            ),
          )
        }
      }}
    >
      <SelectTrigger className="max-sm:p-0 max-sm:w-min border border-gray-100 data-[state=open]:shadow data-[state=open]:shadow-blue-200 data-[state=open]:text-blue-500 h-12">
        <SelectValue aria-label={value}>
          <span className="flex">
            {createElement(resolvePlatformIcons(value), {
              className: "w-5 h-5 ml-2",
            })}
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="bg-white py-1">
        <SelectItem
          value="all"
          className="cursor-pointer data-[state=checked]:text-blue-500"
        >
          <span className="flex flex-row gap-x-2">
            <SvgDevices />
            All platforms
          </span>
        </SelectItem>
        {PLATFORMS.map((platform) => (
          <SelectItem
            key={platform}
            value={platform}
            className="cursor-pointer data-[state=checked]:text-blue-500"
          >
            <span className="flex flex-row gap-x-2">
              {createElement(resolvePlatformIcons(platform), {
                className: "w-5 h-5",
              })}
              {resolvePlatformTitle(platform)}
            </span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export const resolvePlatformIcons = (
  platform: Exclude<ListingPlatform, "platform.pwa"> | "all" | undefined,
) => {
  if (!platform || platform === "all") return SvgDevices

  return {
    [PLATFORM_PWA]: SvgWebapp,
    [PLATFORM_WEB_APP]: SvgWebapp,
    [PLATFORM_IOS]: SvgIos,
    [PLATFORM_ANDROID]: SvgAndroid,
    [PLATFORM_MACOS]: SvgMacOs,
  }[platform]
}
