"use client"

import { SidebarItem } from "./SidebarItem"
import {
  SvgBookOpen,
  SvgCog,
  SvgDownload,
  SvgHammer,
  SvgHeartOutline,
  SvgLightbulb,
  SvgList,
  SvgNavigation,
  SvgProfile,
} from "@store-platform/ui/icons"
import {
  AppIcon,
  NavBarAccordion,
  NavBarAccordionContent,
  NavBarAccordionItem,
  NavBarAccordionTrigger,
  PwaInstallDialogTrigger,
  selectLists,
  useAddToList,
  useAuthModal,
  useIsInstalled,
  usePlatformState,
} from "@store-platform/ui/common"
import { cn, getBestIcon, getNewPlatformLink } from "@store-platform/utils"
import { ClaimedApp } from "@store-platform/types"
import { useShallow } from "zustand/react/shallow"
import { useUser } from "@clerk/nextjs"
import { useRole } from "@store-platform/backend/api-client"
import { MouseEventHandler, useMemo } from "react"

type SidebarProps = {
  mobileDrawer?: boolean
  apps: ClaimedApp[] | null
}

const settingsPattern = /^\/settings\/*/
const browsePattern = /^\/($|browse|search\/*)/ // matches empty pathname or /browse/*

export const SidebarContent = (props: SidebarProps) => {
  const { isSignedIn, user } = useUser()
  const { isDev } = useRole()
  const isInstalled = useIsInstalled()
  const { open } = useAuthModal()
  const platformState = usePlatformState()

  const claimedApps = props.apps ?? []

  const authGuard = useMemo<
    (redirectUrl?: string) => { asButton: boolean; onClick?: MouseEventHandler }
  >(
    () => (redirectUrl?: string) =>
      isSignedIn
        ? { asButton: false }
        : {
            asButton: true,
            onClick: () => open({ redirectUrl }),
          },
    [isSignedIn, open],
  )

  return (
    <nav
      className={cn("overflow-y-scroll scrollbar-hide", {
        "p-3": !!props.mobileDrawer,
      })}
    >
      <div
        className={cn("flex flex-col gap-1", {
          "gap-2 items-start": !!props.mobileDrawer,
        })}
      >
        <SidebarItem
          href={"/foryou"}
          label="For You"
          icon={<SvgLightbulb width={24} height={24} />}
          mobileDrawer={props.mobileDrawer}
          {...authGuard(`/foryou`)}
        />
        <SidebarItem
          href={
            platformState ? getNewPlatformLink({}, platformState, true) : "/"
          }
          label="Browse"
          icon={<SvgNavigation width={24} height={24} />}
          mobileDrawer={props.mobileDrawer}
          pattern={browsePattern}
        />
        <SidebarItem
          href={`/user/${user?.username}`}
          label="Profile"
          icon={<SvgProfile width={24} height={24} />}
          mobileDrawer={props.mobileDrawer}
          {...authGuard(`/user`)}
        />
        <SidebarItem
          href={"/settings/account"}
          label="Settings"
          icon={<SvgCog width={24} height={24} />}
          mobileDrawer={props.mobileDrawer}
          pattern={settingsPattern}
          {...authGuard("/settings/account")}
        />
        {props.mobileDrawer && !user && (
          <SidebarItem
            href="/about"
            asExternal
            label="About Us"
            icon={<SvgBookOpen width={24} height={24} />}
            mobileDrawer
          />
        )}
        {isDev && (
          <SidebarItem
            href={`/developer`}
            label="Developer"
            icon={<SvgHammer width={24} height={24} />}
            mobileDrawer={props.mobileDrawer}
          />
        )}
        {!props.mobileDrawer && !isInstalled && (
          <PwaInstallDialogTrigger
            trackingSource="Desktop Sidebar"
            variant="secondary"
            className="w-full mt-2"
          >
            <SvgDownload />
            <span className={cn({ "hidden lg:inline": !props.mobileDrawer })}>
              Install App
            </span>
          </PwaInstallDialogTrigger>
        )}
      </div>

      {isDev && !!claimedApps.length && (
        // todo Migrate to use Accordion and get rid of specific NavBarAccordion component
        <NavBarAccordion type="single" defaultValue="apps" collapsible>
          <NavBarAccordionItem value="apps">
            <NavBarAccordionTrigger>Claimed Apps</NavBarAccordionTrigger>
            <NavBarAccordionContent>
              {claimedApps.map((listing) => (
                <SidebarItem
                  key={listing.id}
                  href={`/developer/${listing.id}`}
                  label={listing.name || listing.id}
                  mobileDrawer={props.mobileDrawer}
                  icon={
                    <AppIcon
                      imgUrl={getBestIcon(listing.icons)?.src}
                      name={listing.name}
                      size={48}
                      className="h-8 w-8"
                    />
                  }
                />
              ))}
            </NavBarAccordionContent>
          </NavBarAccordionItem>
        </NavBarAccordion>
      )}
      <SidebarLists mobileDrawer={props.mobileDrawer} />
    </nav>
  )
}

const SidebarLists = ({ mobileDrawer }: { mobileDrawer?: boolean }) => {
  const lists = useAddToList(useShallow(selectLists))
  const { isLoaded, isSignedIn, user } = useUser()

  if (!isLoaded || !isSignedIn || !lists?.length) return null

  return (
    <NavBarAccordion type="single" defaultValue="lists" collapsible>
      <NavBarAccordionItem value="lists">
        <NavBarAccordionTrigger>Lists</NavBarAccordionTrigger>
        <NavBarAccordionContent>
          {lists.map((list) => (
            <SidebarItem
              key={list.id}
              href={`/user/${user.username}/lists/${list.slug}`}
              label={list.name}
              mobileDrawer={mobileDrawer}
              icon={
                list.listType === "favorites" ? (
                  <SvgHeartOutline width={24} height={24} />
                ) : (
                  <SvgList width={24} height={24} />
                )
              }
            />
          ))}
        </NavBarAccordionContent>
      </NavBarAccordionItem>
    </NavBarAccordion>
  )
}
