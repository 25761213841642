import { SvgArrowRight, SvgSearch } from "@store-platform/ui/icons"
import {
  Button,
  Link,
  SearchDropdownResult,
  usePlatformState,
} from "@store-platform/ui/common"
import {
  Categories,
  cn,
  getNewPlatformLink,
  platformIdToBrowsingSlug,
  toUrlSafeString,
} from "@store-platform/utils"
import {
  SearchComponentProps,
  searchPrefix,
} from "../../../../_components/Search"
import { CommandGroup, CommandItem, CommandList } from "cmdk"
import { useRecentSearches } from "../../../../_components/hooks/useRecentSearches"
import { ListingPlatform } from "@store-platform/types"

const highlightedCategories = [
  "ai",
  "crypto",
  "games",
  "productivity",
  "developer-tools",
  "social",
]

const categoryColors: {
  [key: (typeof highlightedCategories)[number]]: string
} = {
  ai: "text-pink-700 bg-pink-50",
  crypto: "text-yellow-700 bg-yellow-50",
  games: "text-blue-700 bg-blue-50",
  productivity: "text-purple-700 bg-purple-50",
  "developer-tools": "text-red-700 bg-red-50",
  social: "text-green-700 bg-green-50",
}

const getSearchPageLink = (
  query: string,
  platform: ListingPlatform | undefined,
) => {
  return `${searchPrefix}${platformIdToBrowsingSlug(platform ?? "all")}/${toUrlSafeString(query)}`
}

export const SearchList = ({
  hits,
  query,
  selectedValue,
  setSelectedValue,
}: Pick<SearchComponentProps, "hits" | "query"> & {
  selectedValue: string
  setSelectedValue: (val: string) => void
}) => {
  const { recentSearches, addRecentSearch, clearRecentSearch } =
    useRecentSearches()

  const globalStatePlatform = usePlatformState()

  const searchPageLink = getSearchPageLink(query, globalStatePlatform)

  const categoryObjects = highlightedCategories.map((category) => {
    return {
      ...Categories[category],
      color: categoryColors[category],
    }
  })

  return (
    <CommandList className="p-2">
      {query ? (
        <>
          {/* All Results */}
          <CommandItem value={searchPageLink}>
            <Link
              variant="naked"
              tracker={{
                obj: "Search",
                action: "Result Interacted",
                properties: {
                  query,
                  subject: "All Results",
                },
              }}
              href={searchPageLink}
              onMouseEnter={() => setSelectedValue(searchPageLink)}
              onClick={() => {
                addRecentSearch(query.replace(searchPrefix, ""))
              }}
              className={cn(
                "flex cursor-pointer w-full flex-row items-center justify-between rounded-lg p-2 sm:py-4",
                { "bg-gray-50": selectedValue === searchPageLink },
              )}
            >
              <h2 className="inline-flex gap-x-1 whitespace-nowrap text-blue-500">
                See all results
              </h2>
              <SvgArrowRight className="h-6 w-6 text-blue-500" />
            </Link>
          </CommandItem>
          {/* Listing Results */}
          {!!hits.length && (
            <CommandGroup
              className="text-sm font-semibold text-gray-500"
              heading="Apps"
              hidden={!hits.length}
            >
              {hits.map((listing) => {
                const value = `/${listing.id}`
                return (
                  <CommandItem
                    onMouseEnter={() => setSelectedValue(value)}
                    key={listing.id}
                    value={value}
                  >
                    <SearchDropdownResult
                      listing={listing}
                      selected={value === selectedValue}
                    />
                  </CommandItem>
                )
              })}
            </CommandGroup>
          )}
        </>
      ) : (
        <>
          {/* Recent Searches */}
          {!!recentSearches.length && (
            <CommandGroup
              heading={
                <div className="flex justify-between items-center pt-2">
                  <h2 className="text-sm font-semibold text-gray-500">
                    Recent Searches
                  </h2>
                  <Button
                    tracker={{
                      obj: "Search",
                      action: "History Cleared",
                    }}
                    variant="naked"
                    className="text-blue-500 text-sm"
                    onClick={clearRecentSearch}
                  >
                    Clear all
                  </Button>
                </div>
              }
            >
              {recentSearches.map((searchQuery) => {
                const val = getSearchPageLink(searchQuery, globalStatePlatform)
                return (
                  <CommandItem
                    key={searchQuery}
                    value={val}
                    onMouseEnter={() => setSelectedValue(val)}
                    className={cn(
                      "flex cursor-pointer flex-col items-start justify-between rounded-lg px-2",
                      {
                        "bg-gray-50": selectedValue === val,
                      },
                    )}
                  >
                    <Link
                      variant="naked"
                      tracker={{
                        obj: "Search",
                        action: "Result Interacted",
                        properties: {
                          query,
                          subject: "Recent Searches",
                          value: searchQuery,
                        },
                      }}
                      href={val}
                      className="w-full py-3"
                    >
                      <div className="flex w-full flex-row items-center justify-between">
                        <div className="flex flex-row items-center space-x-2">
                          <SvgSearch />
                          <h3 className="text-sm font-semibold text-gray-800">
                            {searchQuery}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </CommandItem>
                )
              })}
            </CommandGroup>
          )}
          {/* Highlighted Categories */}
          <CommandGroup
            heading="Categories"
            className="text-sm font-semibold text-gray-500"
          >
            {categoryObjects.map((category) => {
              const href = getNewPlatformLink(
                {
                  attributes: [`category.${category.id}`],
                },
                globalStatePlatform,
                false,
              )
              return (
                <CommandItem
                  key={category.id}
                  value={href}
                  className={cn(
                    "flex cursor-pointer flex-col items-start justify-between rounded-lg px-2",
                    {
                      "bg-gray-50": selectedValue === href,
                    },
                  )}
                >
                  <Link
                    tracker={{
                      obj: "Search",
                      action: "Result Interacted",
                      properties: {
                        query,
                        subject: "Category",
                        value: category.name,
                      },
                    }}
                    variant="naked"
                    href={href || ""}
                    className="flex flex-row items-center space-x-2 w-full py-3"
                  >
                    <div className={cn("p-2 rounded-full", category.color)}>
                      {category.icon && <category.icon />}
                    </div>
                    <div className="inline-flex items-center gap-x-1">
                      <h3 className="text-sm font-semibold text-gray-800">
                        {category.name}
                      </h3>
                    </div>
                  </Link>
                </CommandItem>
              )
            })}
          </CommandGroup>
        </>
      )}
    </CommandList>
  )
}
